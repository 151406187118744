import './App.css';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';

import Header from './components/Header/Header';
import Landing from './pages/Landing/Landing';
import PolaroidWall from './pages/PolaroidWall/PolaroidWall';
import EventContainer from './pages/EventContainer/EventContainer';
import Login from './pages/Login/Login';
import Footer from './components/Footer/Footer';

import { useState } from 'react';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

function App(props) {

  const [currentPage, setPage] = useState(localStorage.getItem('currentPage'));
  
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  const [update, setUpdate] = useState(false); 
  const [didUpdate, updated] = useState(false); 

  const setLoggedInInfo = (loginData) => {
    setLoggedIn(true);
    setAdmin(loginData.data.login.user.admin);
  }

  if (update) {
    setUpdate(false);
    updated(true);
  }

  const logout = () => {
    setLoggedIn(false);
    setAdmin(false);
  }

  if (currentPage == null) setPage('landing');

  const renderPage = (currentPage) => {
    if (currentPage == "landing") {
      localStorage.setItem('currentPage', 'landing');
      return <Landing page={setPage} loggedIn={isLoggedIn} logout={logout}/>;
    } else if (currentPage == "polaroidwall"){
      localStorage.setItem('currentPage', 'polaroidwall');
      return <PolaroidWall/>
    } else if (currentPage == "events") {
      localStorage.setItem('currentPage', 'events');
      return <EventContainer admin={isAdmin} setUpdate={setUpdate} updated={didUpdate}/>
    } else if (currentPage == "login") {
      localStorage.setItem('currentPage', 'login');
      return <Login setLoggedInInfo={setLoggedInInfo} page={setPage}/>
    }
  }

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Header updated={didUpdate}/>
        {renderPage(currentPage)}
        <Footer page={setPage}/>
      </ApolloProvider>
    </div>
  );
}

export default App;
