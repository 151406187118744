import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query GetUsers {
        user {
        _id
        imageURL
        insta
        payment {
            cashapp
            venmo
        }
        name
        snap
        age
        }
    }
`;

export const GET_FIRST = gql`
query FirstAnnouncement {
  firstAnnouncement {
    date
  }
}`

export const GET_ANNOUNCEMENTS = gql`
query getAnnouncements {
    announcement {
      _id
      date
      description
      party
      partyInvite
    }
  }
`;