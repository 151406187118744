const dateConvert = (date) => {
    const dateObj = new Date(parseInt(date));
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const addition = month < 10 ? "0" : "";
    const dateAddition = day < 10 ? "0" : ""; 
    const finalDateStr = `${addition}${month}/${dateAddition}${day + 1}/${dateObj.getFullYear()}`
    return finalDateStr;
}

const dateConvertWeekday = (date) => {
    const dateObj = new Date(parseInt(date));
    dateObj.setDate(dateObj.getDate() + 1);
    const options = { weekday: "long" };
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const monthAddition = month < 10 ? "0" : ""; 
    const dateAddition = day < 10 ? "0" : "";
    const finalDateStr = `${new Intl.DateTimeFormat("en-US", options).format(dateObj)} ${monthAddition}${month}/${dateAddition}${day}/${dateObj.getFullYear()}`
    return finalDateStr;
}

const timeTilParty = (date) => {
    // var finalDateStr = [true, true, true];
    var finalDateStr = [];
    
    const dateObj = new Date(parseInt(date));
    const currentDay = new Date(Date.now());
    dateObj.setDate(dateObj.getDate() + 1);

    finalDateStr[2] = true;

    // if (dateObj.getFullYear() == currentDay.getFullYear()) {
    //     if (dateObj.getMonth() > currentDay.getMonth()) {
    //         finalDateStr[0] = "";
    //     } else if (dateObj.getMonth() == currentDay.getMonth()) {
    //         if (dateObj.getDate() > currentDay.getDate()) {
    //             finalDateStr[0] = "";
    //         } else if (dateObj.getDate() == currentDay.getDate()) {
    //             finalDateStr[0] = " Today";
    //             finalDateStr[1] = false;
    //         } else if (dateObj.getDate() - currentDay.getDate() == -1) {
    //             finalDateStr[0] = " Yesterday";
    //             finalDateStr[2] = false;
    //         } else if (dateObj.getDate() < currentDay.getDate()) {
    //             finalDateStr[0] = "";
    //             finalDateStr[1] = false;
    //             finalDateStr[2] = false;
    //         }
    //     } else if (dateObj.getMonth() < currentDay.getMonth()) {
    //         finalDateStr[1] = false;
    //         finalDateStr[2] = false;
    //     }
    // }

    const sConditional = (num) => {
        return (num != 1) ? "s" : "";
    }

    const subtracted = dateObj.getDate() - currentDay.getDate();
    const monthSub = dateObj.getMonth() - currentDay.getMonth();
    //todo: fix this into two separate conditions- consider yesterday function erasion (maybe not)
    if (subtracted < 0 && monthSub >= 0) {
        // finalDateStr[0] = ` ${currentDay.getDate() > 15 ? 30 - currentDay.getDate() + 5 : 5 - currentDay.getDate()} day${(subtracted != 1) ? "s" : ""}`
        finalDateStr[0] = ` ${30 + subtracted} day${sConditional(subtracted)}`
        finalDateStr[1] = true;
    } else if (subtracted == 0) {
        finalDateStr[0] = " Today";
        finalDateStr[1] = false;
    } else if (subtracted == -1) {
        finalDateStr[0] = " Yesterday";
        finalDateStr[1] = false;
        finalDateStr[2] = false;
    } else if (subtracted < -1) {
        finalDateStr[0] = " " + dateConvert(date);
        finalDateStr[1] = false;
        finalDateStr[2] = false;
    } else {
        finalDateStr[0] = ` ${subtracted} day${sConditional(subtracted)}`;
        finalDateStr[1] = true;
    }
    
    return finalDateStr;
}

module.exports = { dateConvert, dateConvertWeekday, timeTilParty }