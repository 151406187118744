import './Landing.css';

import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../util/queries'

import Portrait from '../../components/Portrait/Portrait'

const Landing = (props) => {

    const { loading, data } = useQuery(GET_USERS);

    if (loading) {
        return <div className='loading'><h2>Loading Resident Info...</h2></div>;
    } 

    const login = () => {
        props.page("login");
    }

    return (
        <div className='landingContainer'>
            {data.user.map(({_id, name, age, imageURL, insta, snap, payment})=>{return name !== "Guest" ? <Portrait key={_id} venmo={payment[0].venmo} insta={insta} snap={snap} cashapp={payment[0].cashapp} name={name} age={age} imageURL={imageURL}/> : ''})}
            {props.loggedIn ? <a className="logout" onClick={props.logout}>Log Out</a> : <a className="login" onClick={login}>Log In</a>}
        </div>
    );

};

export default Landing;