import { gql } from '@apollo/client';

export const LOGIN = gql`
mutation login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    user {
      admin
      _id
      username
    }
  }
}
`;

export const NEW_POST = gql`
mutation Mutation($date: String!, $description: String, $party: String, $partyInvite: String) {
  createAnnouncement(date: $date, description: $description, party: $party, partyInvite: $partyInvite) {
    partyInvite
    party
    description
    date
    _id
  }
}
`;