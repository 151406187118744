import './Header.css';

import { useQuery } from '@apollo/client';
import { GET_FIRST } from '../../util/queries'
import * as helper from '../../util/helpers';


const Header = (props) => {

    const { loading, data, refetch } = useQuery(GET_FIRST);
    var response;

    if(loading) {
        return (
        <div className='headerContainer'>
            <h3>Next Party: <span style={{"color":"orange"}}>Loading Data</span></h3>
        </div>
        );
    } else {
        response = helper.timeTilParty(data.firstAnnouncement[0].date)
    }
    
    if (props.updated) {
        refetch();
    }

    return (
        <div className='headerContainer'>
            {<h3>{response[2] ? "Next" : "Last"} Party {response[1] ? "in" : response[2]? "is": "was"}<span style={{"color":"orange"}}>{response[0]}</span></h3>}
            {/* <h3>Next Party: 
                <br></br><span style={{"color":"orange"}}>{helper.dateConvertWeekday(data.firstAnnouncement[0].date)}</span></h3> */}
        </div>
    );

};

export default Header;