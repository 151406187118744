import './Event.css';

import { useState } from 'react';

import { Icon } from '@iconify/react';

const Event = (props) => {

    const [firstLoad, setState] = useState(false);

    if(firstLoad == false){
        setState(true);
    }

    return (
        <div className='event'>
            <div>
                <div style={{"height":"80px"}}>
                    <h2 className='eventTitle'>{props.party}</h2>
                    <h4 className='eventDate'>{props.date}</h4>
                    {props.partyInvite ? <a className="inviteButton" href={props.partyInvite} target={"_blank"}><Icon icon="ic:baseline-insert-invitation" className='icon'/></a> : ''}
                </div>
                <p className='eventPara'>{props.description}</p>
            </div>
        </div>
    );

};

export default Event;