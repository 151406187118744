import './EventContainer.css';
import Event from '../../components/Event/Event';

import * as helper from '../../util/helpers';

import { useQuery } from '@apollo/client';
import { GET_ANNOUNCEMENTS } from '../../util/queries'
import NewEvent from '../../components/NewEvent/NewEvent';

const EventContainer = (props) => {

    const { loading, data, refetch } = useQuery(GET_ANNOUNCEMENTS);

    if (props.updated) {
        refetch()
    }

    if(loading) {
        return <div className='loading'><h2>Loading Event Info...</h2></div>;
    }

    return (
        <div className='eventContainer'>
            {props.admin ? <NewEvent setUpdate={props.setUpdate}/> : ""}
            {data.announcement.map(({party, date, description, partyInvite, _id}) => {return <Event party={party} date={helper.dateConvert(date)} description={description} partyInvite={partyInvite} key={_id}/>})}
        </div>
    );

};

export default EventContainer;