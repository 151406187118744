import './Login.css';

import { LOGIN } from '../../util/mutations';

import { useState } from 'react';
import { useMutation } from '@apollo/client';

const Login = (props) => {

    const [formState, setFormState] = useState({ username: '', password: '' });
    const [login, { error }] = useMutation(LOGIN);
    const [loginError, setError] = useState("");

    const [attempts, setAttempt] = useState(0);

    const submit = async (event) => {
        event.preventDefault();
        try {
          const loginResponse = await login({
            variables: { username: formState.username, password: formState.password },
          });
          if (loginResponse.data.login) {
            props.setLoggedInInfo(loginResponse);
            props.page("landing")
          } else if (attempts == 2) {
            setAttempt(attempts + 1);
            setError("Let's face it, you don't know this shit.");
          } else if (attempts > 2) {
            window.location.assign('https://www.youtube.com/watch?v=dQw4w9WgXcQ');
          } else {
            setAttempt(attempts + 1);
            setError("Incorrect Credentials");
          }
        } catch (e) {
          setError("Incorrect Credentials");
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
          ...formState,
          [name]: value,
        });
      };

    return (
        <div className='loginContainer'>
            <form className='form' onSubmit={submit}>
                <div className='input'>
                    <h1>Username</h1>
                    <input name="username" type="text" onChange={handleChange}/>
                </div>
                <div className='input'>
                    <h1>Password</h1>
                    <input name="password" type="password" onChange={handleChange}/>
                </div>
                <div className='loginErrorContainer'>
                  <h4 style={{"color":"red"}}>{loginError}</h4>
                  <button className='button'>Login</button>
                </div>
            </form>
        </div>
    );

};

export default Login;