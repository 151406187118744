import './Portrait.css';

import { Icon } from '@iconify/react';

const Portrait = (props) => {

    const venmo = () => {
        window.open(`https://venmo.com/${props.venmo}`);
    }

    const insta = () => {
        window.open(`instagram://user?username=${props.insta}`);
    }

    const snap = () => {
        window.open(`snapchat://add/${props.snap}`);
    }

    const cashapp = () => {
        window.open(`https://cash.app/${props.cashapp}`);
    }

    const formatAge = (age) => {
        const ageDate = new Date(parseInt(age))
        const current = new Date();

        let final = current.getFullYear() - ageDate.getFullYear()

        if (ageDate.getMonth() > current.getMonth()) {
            final--;
        } else if (ageDate.getMonth() == current.getMonth() && ageDate.getDate() > current.getDate()) {
            final--;
        } 

        return final;
    }

    return (
        <div className='portrait-container'>
            <div className='outerBox'>
                <div className='titleBox'>
                    <h2 className='title'>{props.name}</h2>
                    <h2 className='major'>{formatAge(props.age)}</h2>
                </div>
                <div className='imageDiv' style={{"backgroundImage": `url(/assets/images/${props.imageURL})`}}></div>
                <div className='bottomBox'>
                    {props.venmo ? <Icon id="venmo" onClick={venmo} className="icons" icon="ion:logo-venmo" /> : ''}
                    {props.insta ? <Icon id="insta" onClick={insta} className="icons" icon="uil:instagram-alt" />:  ''}
                    {props.snap ? <Icon id="snap" onClick={snap} className="icons" icon="uil:snapchat-square" /> : ''}
                    {props.cashapp ? <Icon id="cashapp" onClick={cashapp} className="icons" icon="simple-icons:cashapp"/> : ''}
                </div>
            </div>
        </div>
    );

};

export default Portrait;