import './Footer.css';

const Footer = (props) => {

    const landing = () => {
        props.page("landing");
    }

    const events = () => {
        props.page("events");
    }

    const polaroid = () => {
        props.page("polaroidwall");
    }

    return (
        <div className='footerContainer'>
            <a onClick={landing}>About Us</a>
            <a onClick={events}>Events</a>
            {false ? <a onClick={polaroid}>Polaroid Wall</a> : ''}
        </div>
    );

};

export default Footer;