import './NewEvent.css';

import { useMutation } from '@apollo/client';

import { useState } from 'react';
import { NEW_POST } from '../../util/mutations';

const NewEvent = (props) => {

    const [formData, setForm] = useState({party: '', date: '', description: '', partyInvite: ''});

    const [newpost, { error }] = useMutation(NEW_POST);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
    
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
    
        try {
            const { data } = await newpost({
                variables: { ...formData },
              });
              props.setUpdate(true);
        } catch (e) {
          console.error(e);
        }
    
        // clear form values
        setForm({
            party: '',
            date: '',
            description: '',
            partyInvite: ''
        });
      };

    return (
        <div className='newEvent'>
            <div>
                <div>
                    <h2 className='newEventInvite'>Add New Event</h2>
                </div>
                <form className='' onSubmit={handleFormSubmit} method="post" id='form'>
                    <div>
                        <h3>Party</h3>
                        <input type="text" name="party" id="party" onChange={handleInputChange}/>
                    </div>

                    <div>
                        <h3>Date</h3>
                        <input type="date" name="date" id="date" onChange={handleInputChange}/>
                    </div>  

                    <div>
                        <h3>Description</h3>
                        <input type="text" name="description" id="desc" onChange={handleInputChange}/>
                    </div>  

                    <div>
                        <h3>Party Invite URL</h3>
                        <input type="text" name="partyInvite" id="inv" onChange={handleInputChange}/>
                    </div>  

                    <div>
                        <input type="submit" name="submit" id="submit" value="Submit Event"/>
                    </div>

                </form>
            </div>
        </div>
    );

};

export default NewEvent;