import './PolaroidWall.css';

import { useState } from 'react';

import Polaroid from '../../components/Polaroid/Polaroid';

const PolaroidWall = (props) => {

    return (
        <div className='PolaroidWall-container'>
            <div className='construction'>
                Under Construction :D
            </div>
        </div>
    )

    return (
        <div className='PolaroidWall-container'>
            <Polaroid key={props.id} venmo={"alexbradshaw5"} insta={"alexbradshaw5"} snap={"alexbradshaw75"} cashapp={"alexbradshaw5"} title={"Carrot"} date={2023} imageURL={"https://lh3.googleusercontent.com/wa19zRv05W2P3naQQAXnsz-nx6RRiofA0q-0KoZCSKrAZKwh0_0vVC0TgubQKcqlG-A=w2400"} party={"New Years"} style={{"boxShadow" : "5px 10px 18px #888888"}}/>
            <Polaroid key={props.id} venmo={"alexbradshaw5"} insta={"alexbradshaw5"} snap={"alexbradshaw75"} cashapp={"alexbradshaw5"} title={"Carrot"} date={2023} imageURL={"https://lh3.googleusercontent.com/wa19zRv05W2P3naQQAXnsz-nx6RRiofA0q-0KoZCSKrAZKwh0_0vVC0TgubQKcqlG-A=w2400"} party={"New Years"}/>
            <Polaroid key={props.id} venmo={"alexbradshaw5"} insta={"alexbradshaw5"} snap={"alexbradshaw75"} cashapp={"alexbradshaw5"} title={"Carrot"} date={2023} imageURL={"https://lh3.googleusercontent.com/wa19zRv05W2P3naQQAXnsz-nx6RRiofA0q-0KoZCSKrAZKwh0_0vVC0TgubQKcqlG-A=w2400"} party={"New Years"}/>
            <Polaroid key={props.id} venmo={"alexbradshaw5"} insta={"alexbradshaw5"} snap={"alexbradshaw75"} cashapp={"alexbradshaw5"} title={"Carrot"} date={2023} imageURL={"https://lh3.googleusercontent.com/wa19zRv05W2P3naQQAXnsz-nx6RRiofA0q-0KoZCSKrAZKwh0_0vVC0TgubQKcqlG-A=w2400"} party={"New Years"}/>
        </div>
    );

};

export default PolaroidWall;